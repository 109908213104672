import React from "react";
import { graphql } from "gatsby";
import { Seo } from "../../components/Seo";
import { useSolutions } from "../../hooks/methods/solutions";
import Layout from "../../templates/MainTemplate";
import { CompaniesView } from "../../view/Home/companies";
import { BannerSolutions } from "../../view/solutions/bannerSolutions";
import { DepositionsSolutions } from "../../view/solutions/depositionsSolutions";
import { DescriptionSolutions } from "../../view/solutions/descriptionSolutions";
import { FormSolutions } from "../../view/solutions/formSolutions/formSolutions";
import { LgpdSolutions } from "../../view/solutions/lgpdSolutions";
import { ResourcesSolutions } from "../../view/solutions/resourcesSolutions";
import { TechnologiesSolutions } from "../../view/solutions/technologiesSolutions";

export default function Solutions() {
  const { data, isSuccess } = useSolutions();

  const {
    bannerSolution,
    depositionsCard,
    infoCard,
    lgpdSolutions,
    productCinnecta,
    technologiesSection,
    exclusiveFeatureSection,
    leadingCompany,
    formTitle,
    formSubtitle,
  } = isSuccess && data?.solutionsPage;

  return (
    <>
      <Layout>
        <BannerSolutions data={isSuccess && bannerSolution} infoCard={infoCard} />
        <DepositionsSolutions data={isSuccess && depositionsCard} />
        <DescriptionSolutions data={productCinnecta} />
        <CompaniesView data={isSuccess && leadingCompany} />
        <ResourcesSolutions data={isSuccess && exclusiveFeatureSection} />
        <TechnologiesSolutions data={isSuccess && technologiesSection} />
        <LgpdSolutions data={isSuccess && lgpdSolutions} />
        <FormSolutions formTitle={formTitle} formSubtitle={formSubtitle} />
      </Layout>
    </>
  );
}

export const Head = ({
  data: {
    cmsSolutionsPage: 
      {seo: { title: seoTitle, description: seoDescription, keywords, image: {url} }},
  },
}) => {
  const keywordsList = keywords.toString()
  return (
    <Seo title={seoTitle} description={seoDescription} keywords={keywordsList} url={url} />
  );
};

export const query = graphql`
  query PageQuery {
    cmsSolutionsPage {
      seo {
        title
        description
        keywords
        image {
          url
        }
      }
    }
  }
`;