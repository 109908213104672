import React from "react";
import { Button } from "../../../../../components/Button/Button";
import { ContentDescription } from "./styledDescriptionBanner";
import { Link } from "gatsby";

export function DescriptionBanner({ title, subTitle, paragraph, labelButton, buttonLink, buttonSlug }) {
  return (
    <ContentDescription>
      {title && <h1>{title}</h1>}
      {subTitle && <h2>{subTitle}</h2>}
      
      <p dangerouslySetInnerHTML={{ __html: paragraph }} />
      {buttonSlug ? (
          <Link to={`/${buttonSlug}`}>
            <Button type="button" label={labelButton} />
          </Link>
        ) : (
          <a href={buttonLink} target="blank">
            <Button type="button" label={labelButton} />
          </a>
        )}
    </ContentDescription>
  );
}
