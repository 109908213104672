import React from "react";
import { ContainerCard, StyledBannerSolutions } from "./styledBanner";
import { CardFeatures } from "./components/cardFeatures";
import { DescriptionBanner } from "./components/descriptionBanner";

export const BannerSolutions = ({ data, infoCard }) => {
  const { id, titleBanner, subTitleBanner, bodyBanner, buttonText, buttonLink, buttonSlug } = data;

  return (
    <StyledBannerSolutions id="cinnecta_ltv">
      <DescriptionBanner
        key={id}
        title={titleBanner}
        subTitle={subTitleBanner}
        paragraph={bodyBanner?.html}
        labelButton={buttonText}
        buttonLink={buttonLink}
        buttonSlug={buttonSlug}
      />

      <img src={data?.imageBanner?.url} alt="imagem banner" loading="lazy" />
      <ContainerCard>
        {infoCard?.map(({ id, titleInfo, bodyInfo }) => (
          <CardFeatures key={id} label={titleInfo} description={bodyInfo} />
        ))}
      </ContainerCard>
    </StyledBannerSolutions>
  );
};
