import { Link } from "gatsby";
import React from "react";
import { Button } from "../../../components/Button/Button";
import { Title } from "../../../components/Title/Title";
import { CardTechnologies } from "./components/cardTechnologies";
import {
  ContainerCardTechnologies,
  StyledTechnologiesSolutions,
} from "./styledTechnologiesSolutions";

export const TechnologiesSolutions = ({ data }) => {
  const objectData = data?.technologiesCard === undefined ? [] : data?.technologiesCard;
  const linkTo = `/${data?.technologiesButtonSlug}`

  return ( 
    <StyledTechnologiesSolutions>
      <Title label={data?.technologiesTitle} />
      <ContainerCardTechnologies>
        {objectData?.map(({ id, titleInfo, bodyInfo }) => (
          <CardTechnologies key={id} title={titleInfo} description={bodyInfo} />
        ))}
      </ContainerCardTechnologies>
      {data?.technologiesButtonSlug ? (
        <Link to={linkTo}>
          <Button
            type="button"
            label={data?.technologiesButton}
          />
        </Link>
      ) : (
        <a href={data?.buttonLink} target="blank">
          <Button
            type="button"
            label={data?.technologiesButton}
          />
        </a>
      )}
    </StyledTechnologiesSolutions>
  );
};
