export const optionsSegmentoEmpresa = [
  {
    optionLabel: "selecione uma opção",
    optionValue: "",
  },
  {
    optionLabel: "Agronegócio",
    optionValue: "Agronegócio",
  },
  {
    optionLabel: "Educação e treinamento",
    optionValue: "Educação e treinamento",
  },
  {
    optionLabel: "Fármaco",
    optionValue: "Fármaco",
  },
  {
    optionLabel: "Financeiro",
    optionValue: "Financeiro",
  },
  {
    optionLabel: "Linhas aéreas",
    optionValue: "Linhas aéreas",
  },
  {
    optionLabel: "Mercado imobiliário e incorporadora",
    optionValue: "Mercado imobiliário e incorporadora",
  },
  {
    optionLabel: "Mobilidade urbana",
    optionValue: "Mobilidade urbana",
  },
  {
    optionLabel: "Saúde",
    optionValue: "Saúde",
  },
  {
    optionLabel: "Seguradora e corretoras",
    optionValue: "Seguradora e corretoras",
  },
  {
    optionLabel: "Tecnologia e inovação",
    optionValue: "Tecnologia e inovação",
  },
  {
    optionLabel: "Telecomunicações",
    optionValue: "Telecomunicações",
  },
  {
    optionLabel: "Varejo",
    optionValue: "Varejo",
  },
  {
    optionLabel: "Outros",
    optionValue: "Outros",
  },
];

export const optionsSaibaMais = [
  {
    optionLabel: "selecione uma opção",
    optionValue: "",
  },
  {
    optionLabel: "cinnecta revenue",
    optionValue: "cinnecta revenue",
  },
  {
    optionLabel: "ciaaS",
    optionValue: "CiaaS",
  },
  {
    optionLabel: "Cinnecta",
    optionValue: "Cinnecta",
  },
]