import { gql } from "graphql-request";

export const getSolutions = gql`
  query solutionsPage {
    solutionsPage(where: { slug: "solutions" }) {
      seo {
        title
        description
        keywords
        image {
          url
        }
      }
      bannerSolution {
        id
        titleBanner
        subTitleBanner
        bodyBanner {
          html
        }
        imageBanner {
          url
          fileName
        }
        buttonText
        buttonSlug
        buttonLink
      }
      infoCard {
        titleInfo
        id
        bodyInfo
      }
      depositionsCard {
        id
        officeAgent
        nameAgent
        nameCompany
        bodyDeposition
        imageAgent {
          url
        }
        logoCompany {
          url
        }
      }
      leadingCompany {
        titleLeadingCompany
        imageLeadingCompany {
          url
          id
          fileName
          textAlt
        }
      }
      productCinnecta {
        id
        nameProduct
        bodyProduct {
          html
        }
        descriptionProduct
        labelButton
        buttonLink
        buttonSlug
        imageProduct {
          url
        }
      }
      technologiesSection {
        technologiesTitle
        technologiesButton
        technologiesButtonSlug
        buttonLink
        technologiesCard {
          id
          titleInfo
          bodyInfo
        }
      }
      lgpdSolutions {
        id
        nameProduct
        descriptionProduct
        imageProduct {
          url
        }
      }
      exclusiveFeatureSection {
        id
        exclusiveFeatureTitle
        exclusiveFeatureSubtitle
        exclusiveFeatureContent {
          id
          title
          description
        }
        exclusiveFeatureButton
        exclusiveFeatureButtonLink
        buttonLink
        exclusiveFeatureImage {
          id
          url
          textAlt
        }
      }
      formTitle
      formSubtitle
    }
  }
`;
