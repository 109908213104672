import { Link } from "gatsby";
import React from "react";
import { StyledContentSolution } from "./styledContentSolution";
import { Button } from "../../../../../components/Button/Button";

export const ContentSolutions = ({ exclusiveFeature }) => {
  const content = exclusiveFeature
    ? exclusiveFeature?.exclusiveFeatureContent
    : [];

  const linkTo = `/${exclusiveFeature?.exclusiveFeatureButtonLink}`;

  return (
    <StyledContentSolution>
      <h2>{exclusiveFeature?.exclusiveFeatureTitle}</h2>
      {exclusiveFeature?.exclusiveFeatureSubtitle && <p>{exclusiveFeature?.exclusiveFeatureSubtitle}</p>}
      {content?.map(({ title, description }) => (
        <div key={title}>
          {title && <h3>{title} </h3>}
          {description && <p>{description}</p>}
        </div>
      ))}
      {exclusiveFeature?.exclusiveFeatureButtonLink ? (
        <Link to={linkTo}>
          <Button
            type="button"
            label={exclusiveFeature?.exclusiveFeatureButton}
          />
        </Link>
      ) : (
        <a href={exclusiveFeature.buttonLink} target="blank">
          <Button
            type="button"
            label={exclusiveFeature?.exclusiveFeatureButton}
          />
        </a>
      )}
    </StyledContentSolution>
  );
};
