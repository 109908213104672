import React from "react";
import { ContentSolutions } from "./components/contentSolutions";
import { ContainerResources } from "./styledResourcesSolutions";

export const ResourcesSolutions = ({ data }) => {
  return (
    <ContainerResources>
      <ContentSolutions exclusiveFeature={data} />
      <img src={data?.exclusiveFeatureImage?.url} alt={data?.exclusiveFeatureImage?.textAlt} loading="lazy" />
    </ContainerResources>
  );
};
